/**
 * @param {string|number[]} coords
 * @return {LatLng}
 */
import { latLng, LatLng } from "leaflet"

export default function CreateLatLng(coords) {
	if (typeof coords === "string") {
		const [lat, lng] = coords.split(",")

		return latLng(parseFloat(lat), parseFloat(lng))
	}

	return null
}
